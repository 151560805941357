<template>
    <div>
        <b-row>
            <b-col>
                <b-row class="match-height">
                    <b-col align="center">
                        <b-card class="round animate__animated animate__zoomIn">
                            <b-avatar
                                variant="light-primary"
                                rounded-circle
                                size="50"
                            >
                                <feather-icon
                                    icon="HomeIcon"
                                    size="35"
                                    class="pb-25"
                                ></feather-icon>
                            </b-avatar>
                            <p class="mt-1 font-weight-bolder font-large-1">
                                {{ indicadores.count_entidades }}
                            </p>
                            <span>Entidades</span>
                        </b-card>
                    </b-col>
                    <b-col align="center">
                        <b-card class="round animate__animated animate__zoomIn">
                            <b-avatar
                                variant="light-success"
                                rounded-circle
                                size="50"
                            >
                                <feather-icon
                                    icon="UsersIcon"
                                    size="35"
                                    class="pb-25"
                                ></feather-icon>
                            </b-avatar>
                            <p class="mt-1 font-weight-bolder font-large-1">
                                {{ indicadores.count_pacientes }}
                            </p>
                            <span>Pacientes</span>
                        </b-card>
                    </b-col>
                    <b-col align="center">
                        <b-card class="round animate__animated animate__zoomIn">
                            <b-avatar
                                variant="light-info"
                                rounded-circle
                                size="50"
                            >
                                <feather-icon
                                    icon="UsersIcon"
                                    size="35"
                                    class="pb-25"
                                ></feather-icon>
                            </b-avatar>
                            <p class="mt-1 font-weight-bolder font-large-1">
                                {{ indicadores.count_profesionales }}
                            </p>
                            <span>Profesionales</span>
                        </b-card>
                    </b-col>
                    <b-col align="center">
                        <b-card class="round animate__animated animate__zoomIn">
                            <b-avatar
                                variant="light-secondary"
                                rounded-circle
                                size="50"
                            >
                                <feather-icon
                                    icon="HardDriveIcon"
                                    size="35"
                                    class="pb-25"
                                ></feather-icon>
                            </b-avatar>
                            <p class="mt-1 font-weight-bolder font-large-1">
                                {{ indicadores.count_dispositivos }}
                            </p>
                            <span>Dispositivos</span>
                        </b-card>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card no-body>
                    <tabla-general
                        :columns="tableColumns"
                        :items="entidades || undefined"
                        :loading="loading"
                        button-activated
                    >
                        <template v-slot:boton>
                            <b-button
                                variant="success"
                                @click="abrirSidebarCrearEntidad"
                                >Crear</b-button
                            >
                        </template>
                        <template v-slot:cell(nombre)="data">
                            <div
                                @click="
                                    $router.push({
                                        name: 'ver-entidad',
                                        params: { id: data.item.id },
                                    })
                                "
                            >
                                {{ data.item.nombre }}
                            </div>
                        </template>
                        <template v-slot:cell(created_at)="data">
                            <div>
                                {{ formatDate(data.item.created_at) }}
                            </div>
                        </template>
                        <template v-slot:cell(estado)="data">
                            <b-badge
                                v-if="data.item.estado === 1"
                                variant="success"
                                >Activa</b-badge
                            >
                            <b-badge
                                v-if="data.item.estado === 0"
                                variant="danger"
                                >Inactiva</b-badge
                            >
                            <b-badge
                                v-if="data.item.estado === 2"
                                variant="warning"
                                >Stand by</b-badge
                            >
                        </template>
                        <template v-slot:cell(opciones)="data">
                            <b-dropdown variant="link" no-caret>
                                <template v-slot:button-content>
                                    <feather-icon
                                        icon="MoreVerticalIcon"
                                    ></feather-icon>
                                </template>
                                <b-dropdown-item>
                                    <feather-icon icon="EyeIcon"></feather-icon>
                                    Ver</b-dropdown-item
                                >
                                <b-dropdown-item>
                                    <feather-icon
                                        icon="EditIcon"
                                    ></feather-icon>
                                    Editar</b-dropdown-item
                                >
                                <b-dropdown-item>
                                    <feather-icon
                                        icon="EyeOffIcon"
                                    ></feather-icon>
                                    Inactivar</b-dropdown-item
                                >
                                <b-dropdown-item>
                                    <feather-icon
                                        icon="Trash2Icon"
                                    ></feather-icon>
                                    Borrar</b-dropdown-item
                                >
                            </b-dropdown>
                        </template>
                    </tabla-general>
                </b-card>
            </b-col>
        </b-row>
        <!-- sidebars -->
        <sidebar-crear-entidad
            ref="refsidebarCrearEntidad"
        ></sidebar-crear-entidad>
    </div>
</template>
<script>
import Entidades from "@/services/entidades";
import { ref, onMounted } from "@vue/composition-api";
export default {
    components: {
        SidebarCrearEntidad: () =>
            import("../sidebars/SidebarCrearEntidad.vue"),
    },
    setup(props, context) {
        const entidades = ref([]);
        const indicadores = ref({});
        const loading = ref(false);

        const tableColumns = ref([
            { key: "id", sortable: true, label: "#" },
            { key: "estado", sortable: true },
            { key: "nombre", sortable: true },
            // { key: "ciudad", sortable: true },
            { key: "count_pacientes", label: "Pacientes", sortable: true },
            {
                key: "count_profesionales",
                label: "Profesionales",
                sortable: true,
            },
            {
                key: "count_dispositivos",
                label: "Dispositivos",
                sortable: true,
            },
            { key: "created_at", sortable: true, label: "Desde" },
            { key: "opciones" },
        ]);

        function abrirSidebarCrearEntidad() {
            context.refs.refsidebarCrearEntidad.toggle();
        }

        async function fetchListarEntidades() {
            try {
                loading.value = true;
                const { data } = await Entidades.listarEntidadesAdmin();
                entidades.value = data.data;
                indicadores.value = data.indicadores;
            } catch (error) {
                context.root.catch(error);
            } finally {
                loading.value = false;
            }
        }

        onMounted(() => {
            fetchListarEntidades();
        });

        return {
            tableColumns,
            entidades,
            indicadores,
            loading,
            abrirSidebarCrearEntidad,
        };
    },
};
</script>
